<template>
  <div>
    <ejs-dialog
      ref="productCodeSelectPopupDialog"
      header="상품코드 검색"
      :animationSettings="animationSettings"
      :allowDragging="true"
      :showCloseIcon="true"
      width="1180"
      :close="dialogClose"
      :position="{ x: 'center', y: 'center' }"
      :enableResize="false"
      :isModal="true"
    >
      <div class="window productCodeSearch">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field classification">
                    <div class="title">분류</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                          ref="classCode01Dropdown"
                          id="classCode01Dropdown"
                          v-model="searchCondition.classCode01"
                          :dataSource="searchOption.classCode01Options"
                          :change="onClassCode01Changed"
                          :allowFiltering="false"
                          :fields="classCodeFields"
                          placeholder="대분류"
                          cssClass="lookup-condition-dropdown"
                        ></ejs-dropdownlist>
                      </li>
                      <li class="item">
                        <ejs-dropdownlist
                          ref="classCode02Dropdown"
                          id="classCode02Dropdown"
                          v-model="searchCondition.classCode02"
                          :query="searchOption.classCode02Query"
                          :dataSource="searchOption.classCode02Options"
                          :change="onClassCode02Changed"
                          :allowFiltering="false"
                          :fields="classCodeFields"
                          placeholder="중분류"
                          cssClass="lookup-condition-dropdown"
                        ></ejs-dropdownlist>
                      </li>
                      <li class="item">
                        <ejs-dropdownlist
                          ref="classCode03Dropdown"
                          id="classCode03Dropdown"
                          data-msg-containerid="classCode03DropdownError"
                          v-model="searchCondition.classCode03"
                          :query="searchOption.classCode03Query"
                          :dataSource="searchOption.classCode03Options"
                          :allowFiltering="false"
                          :fields="classCodeFields"
                          placeholder="소분류"
                          cssClass="lookup-condition-dropdown"
                        ></ejs-dropdownlist>
                      </li>
                    </ul>
                  </li>
                  <li class="field brandNameCord">
                    <div class="title">상품명/코드</div>
                    <ul class="content">
                      <li class="item">
                        <input-text v-model="searchCondition.productCodeName" />
                      </li>
                    </ul>
                  </li>
                  <li class="field">
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                v-model="searchCondition.optnFlag"
                                value="true"
                              />
                              <i></i>
                              <div class="label">옵션</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :ignore="isPopupOpened"
                      :is-shortcut-button="true"
                      v-on:click.native="searchProduct"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <div>
                  <!-- 아코디언 : accordion / 닫힘 : close -->
                  <section class="article-section section-01">
                    <div class="section-body">
                      <div class="body-grid">
                        <ejs-grid-wrapper
                          ref="Grid"
                          height="383"
                          :allowResizing="true"
                          :dataSource="srcData"
                          :selectionSettings="selectionOptions"
                          :columns="columns"
                          :provides="grid"
                          :allowSelection="true"
                          :allowPaging="true"
                          :pageSettings="{ pageCount: 3, pageSize: 15 }"
                          :allowRowDragAndDrop="true"
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="GET"
                  :is-icon-custom="true"
                  @click.native="confirmProduct"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="dialogClose">
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>
<script>
import {
  Selection,
  Page,
  ForeignKey,
  Aggregate,
  Filter,
  Resize,
  Group,
  VirtualScroll,
} from "@syncfusion/ej2-vue-grids";
import { Query } from "@syncfusion/ej2-data";

import { getProductList } from "@/api/product";
import { getAllProductClassCodes } from "@/api/productClassCode";

import InputText from "@/components/common/text/InputText";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { commonCodesGetComName } from "@/utils/commonCodes";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";

import ErpButton from "@/components/button/ErpButton.vue";

export default {
  name: "ProductCodeSelectPopup",
  components: {
    InputText,
    EjsGridWrapper,
    ErpButton,
  },
  mixins: [confirmDialogMixin],
  data() {
    return {
      grid: [Aggregate, Filter, Resize, Group, ForeignKey, VirtualScroll],
      selectedClasses: {
        classCode01: null,
        classCode02: null,
        classCode03: null,
      },
      animationSettings: { effect: "None" },
      classCodeFields: { text: "className", value: "classCode" },
      searchCondition: {
        classCode01: null,
        classCode02: null,
        classCode03: null,
        productCodeName: null,
        optnFlag: false,
      },
      searchOption: {
        classCode01Options: [],
        classCode02Options: [],
        classCode03Options: [],
        classCode02Query: new Query().where("parentCode", "equal", ""),
        classCode03Query: new Query().where("parentCode", "equal", ""),
      },
      srcData: [],
      columns: [
        {
          field: "chkinId",
          allowEditing: false,
          type: "string",
          visible: false,
        },
        {
          field: "className01",
          headerText: "대분류",
          allowEditing: false,
          minWidth: 16,
          width: 90,
          textAlign: "center",
          type: "string",
        },
        {
          field: "className02",
          headerText: "중분류",
          allowEditing: false,
          minWidth: 16,
          width: 90,
          textAlign: "center",
          type: "string",
        },
        {
          field: "className03",
          headerText: "소분류",
          allowEditing: false,
          minWidth: 16,
          width: 90,
          textAlign: "center",
          type: "string",
        },
        {
          field: "productName",
          headerText: "상품명",
          allowEditing: false,
          minWidth: 16,
          width: 120,
          textAlign: "left",
          type: "string",
        },
        {
          field: "productCode",
          headerText: "상품코드",
          allowEditing: false,
          minWidth: 16,
          width: 180,
          textAlign: "left",
          isPrimaryKey: true,
          type: "string",
        },
        {
          field: "notaxCode",
          headerText: "면/과세",
          allowEditing: false,
          minWidth: 16,
          width: 90,
          textAlign: "center",
          isCommonCodeField: true,
          groupCode: "NOTAX_CODE",
          type: "string",
        },
        {
          field: "price1",
          headerText: "판매금액",
          allowEditing: false,
          minWidth: 16,
          width: 90,
          type: "number",
          format: "N",
          textAlign: "right",
        },
      ],
      selectionOptions: { type: "Multiple" },
    };
  },
  methods: {
    valueAccessor(field, data, column) {
      let code = data[field];
      let returnCode = null;
      if (code != null) {
        returnCode = commonCodesGetComName(column.comCode, code);
      }
      return returnCode;
    },
    showPopup(productCodeName) {
      this.searchCondition.productCodeName = productCodeName;
      this.$refs.productCodeSelectPopupDialog.show();

      this.getAllProductClassCodes();
    },
    async getAllProductClassCodes() {
      try {
        const {
          value: { productClasses01, productClasses02, productClasses03 },
        } = await getAllProductClassCodes();
        this.searchOption.classCode01Options = productClasses01;
        this.searchOption.classCode02Options = productClasses02;
        this.searchOption.classCode03Options = productClasses03;

        this.loadData();
      } catch (e) {
        throw new Error("error loading class codes");
      }
    },
    async fetchProductCodes() {
      const param = {
        isInit: false,
        classCode1: this.searchCondition.classCode01,
        classCode2: this.searchCondition.classCode02,
        classCode3: this.searchCondition.classCode03,
        productCodeName: this.searchCondition.productCodeName,
        optnFlag: this.searchCondition.optnFlag,
      };
      if (param.classCode1 === null) {
        delete param.classCode1;
      }
      if (param.classCode2 === null) {
        delete param.classCode2;
      }
      if (param.classCode3 === null) {
        delete param.classCode3;
      }
      if (param.productCodeName === null) {
        delete param.productCodeName;
      }

      try {
        const { value } = await getProductList(param);
        this.srcData = value.productCodes;
      } catch (e) {
        console.error("fetchProductCodes.err.===>", e);
      }
    },
    loadData() {
      this.fetchProductCodes(true);
    },
    searchProduct() {
      this.fetchProductCodes(false);
    },
    confirmProduct() {
      const selectedRow = this.$refs.Grid.getSelectedRecords();
      if (selectedRow.length < 1) {
        this.errorToast("상품을 선택해 주세요");
        return;
      }

      this.$emit("popupConfirmed", selectedRow[0]);
      this.$refs.productCodeSelectPopupDialog.hide();
    },
    onClassCode01Changed() {
      this.searchOption.classCode02Query = new Query().where(
        "parentCode",
        "equal",
        this.$refs.classCode01Dropdown.ej2Instances.value
      );
    },
    onClassCode02Changed() {
      this.searchOption.classCode03Query = new Query().where(
        "parentCode",
        "equal",
        this.$refs.classCode02Dropdown.ej2Instances.value
      );
    },
    dialogClose() {
      this.$emit("popupClosed");
    },
  },
  provide: {
    grid: [Selection, Page, ForeignKey],
  },
};
</script>

<style scoped></style>
